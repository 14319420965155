<template lang='pug'>
.invite
    .card.mb-3
        .card-body
            h2 Invite the family.
            .text-start
                p.m-1 We are opening this tool to invite our family directly by sending them an email invitation.
                p.m-1 With this, we are restricting the access to only those invited directly from us.
                p.m-1 Please only invite people directly related to our EMI family.
    .card.invitation-form 
        .card-body.text-start
            h5 Send and invitation today:
            .form-floating.mb-3
                input.form-control(type="text" v-model="name" placeholder=" ")
                label Name
            .form-floating.mb-3
                input.form-control(type="email" v-model="email" placeholder=" ")
                label Email
            .form-check.mb-3
              input.form-check-input(type="checkbox" name="validity" value='' v-model="meCheck" id="flexCheckDefault")
              .form-check-label(for='flexCheckDefault') I know this person and their relationship with EMI
            .text-center
                button.btn.btn-outline-secondary(@click="submitIvitation")
                    span.material-icons.m-1 send
                    | Send
</template>

<script>
import API from "./../API.js";
export default {
    data(){
        return {
            email: "",
            name: "",
            meCheck: false,
        }
    },
    methods:{
        submitIvitation(){
            if(!this.name || !this.email || !this.meCheck){
                alert("Please fill up all the information");
                return 0;
            }
            API.newInvitation(this.name, this.email).then(response => {
                if(response.status != "ok"){
                    alert(response.status);
                    return 0;
                }
                this.email = "";
                this.name = "";
                alert("Invitation sent, thank you!");
            });
        }
    }
}
</script>

<style>

</style>